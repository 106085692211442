<template>
    <a-layout>
        <a-layout-sider>
            <a-anchor class="side-nav" :affix="false" @click="handleClick">
                <a-anchor-link v-for="(item,key) in parts" :key="key" :href="item.keys[0]" :title="item.name" />
            </a-anchor>
        </a-layout-sider>
        <a-layout-content>
            <h1 class="h1">指数平滑</h1>
            
            <div class="result-area" id="varsProduce">
                <divider-title name="变量解释" />
                <div>
                    <div v-for="(item,key) in varsProduce" :key="key">
                        {{item.key}}: {{item.value}}
                    </div>
                </div>
            </div>
            <div class="result-area" v-if="modelDesc" id="modelDesc">
                <divider-title name="模型解释" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th style="width:50px"></th>
                                                    <th style="width:150px">方法</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>{{modelDesc.indeptKey}}</td>
                                                    <td>{{modelDesc.modelType}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" v-if="modelDesc" id="modelStats">
                <divider-title name="模型统计量表" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th rowspan="2" style="width:50px">模型</th>
                                                    <th rowspan="2" style="width:150px">
                                                        平稳的R<sup>2</sup>
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.stationaryR2.describe}}
                                                                </p>
                                                                <p>
                                                                    判断：{{itemInfos.stationaryR2.judge}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </th>
                                                    <th rowspan="2" style="width:150px">
                                                        R<sup>2</sup>
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.r2.describe}}
                                                                </p>
                                                                <p>
                                                                    判断：{{itemInfos.r2.judge}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </th>
                                                    <th rowspan="2" style="width:150px">均方根误差</th>
                                                    <th rowspan="2" style="width:150px">残差平方和</th>
                                                    <th colspan="3" style="width:300px">Ljung-BoxQ</th>
                                                    <th rowspan="2"></th>
                                                </tr>
                                                <tr>
                                                    <th style="width:100px">统计量
                                                        <a-tooltip placement="right">
                                                            <template slot="title">
                                                                <p>
                                                                    描述：{{itemInfos.ljungBoxQStata.describe}}
                                                                </p>
                                                            </template>
                                                            <i class="iconfont icon-info"></i>
                                                        </a-tooltip>
                                                    </th>
                                                    <th style="width:100px">df</th>
                                                    <th style="width:100px">Sig.</th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>{{modelStats.indeptKey}}</td>
                                                    <td>{{toFixedNum(modelStats.stationaryR2)}}</td>
                                                    <td>{{toFixedNum(modelStats.r2)}}</td>
                                                    <td>{{toFixedNum(modelStats.rmse)}}</td>
                                                    <td>{{toFixedNum(modelStats.sse)}}</td>
                                                    <td>{{toFixedNum(modelStats.ljungBoxQStata)}}</td>
                                                    <td>{{toFixedNum(modelStats.ljungBoxQDf)}}</td>
                                                    <td>{{toFixedNum(modelStats.ljungBoxQSig)}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" v-if="modelParaEst" id="modelParaEst">
                <divider-title name="指数平滑模型参数估计" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th style="width:100px">模型</th>
                                                    <th style="width:100px">转换类型</th>
                                                    <th style="width:100px">参数</th>
                                                    <th style="width:100px">估计</th>
                                                    <th></th>
                                                </tr>                                                
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>{{modelParaEst.indeptKey}}</td>
                                                    <td>{{modelParaEst.deptTrans}}</td>
                                                    <td>Alpha</td>
                                                    <td>{{modelParaEst.alpha}}</td>
                                                    <td></td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td rowspan="2" colspan="2">最后一个经济周期：</td>
                                                    <td>趋势</td>
                                                    <td>-</td>
                                                    <td></td>
                                                </tr>
                                                <tr class="ant-table-row ant-table-row-level-0">
                                                    <td>均值</td>
                                                    <td>{{toFixedNum(modelParaEst.endOfPeriodLevelsMean)}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" v-if="modelParaEst" id="tx">
                <divider-title name="图形" />
                <chart :chartData="result.lineChart" />
            </div>

        </a-layout-content>
    </a-layout>
</template>

<script>
import { getSessionItem } from '../../until/tools'
import Chart from './components/Chart.vue';
import DividerTitle from './components/DividerTitle.vue';
export default {
    components:{DividerTitle, Chart},
    computed:{
        result() {
            let data = getSessionItem('analysis_expsmooth');
            return data.analysis_expsmooth;
        },
        parts() {
            let arr = [
                {name: '变量解释', keys: ['varsProduce']},
                {name: '模型解释', keys: ['modelDesc']},
                {name: '模型统计量表', keys: ['modelStats']},
                {name: '指数平滑模型参数估计', keys: ['modelParaEst']},
                {name: '图形', keys: ['tx']}
            ]
            return arr;
        },
        varsProduce() {
            if(this.result) {
                return this.result.smoothBO.varsProduce;
            } else {
                return null;
            }
        },
        modelDesc() {
            if(this.result) {
                return this.result.smoothBO.modelDesc;
            } else {
                return null;
            }
        },
        modelStats() {
            if(this.result) {
                return this.result.smoothBO.modelStats;
            } else {
                return null;
            }
        },
        modelParaEst() {
            if(this.result) {
                return this.result.smoothBO.modelParaEst;
            } else {
                return null;
            }
        },
        itemInfos() {
            if(this.result) {
                return this.result.itemInfos;
            } else {
                return null;
            }
        }

        
    },
    methods:{
        handleClick(e,link) {
            e.preventDefault();
            var srcolls = document.getElementById(link.href);
            srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
        toFixedNum(num) {
            if(num) {
                return num.toFixed(4);
            } else {
                return '-';
            }
        }, 
    }
}
</script>